import React from "react";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const GenerateInvoicePDF = (formData: any, orders: any, products: any) => {
  const doc = new jsPDF();
  const productMap: { [key: number]: string } = {};
  doc.addFileToVFS("NotoSansTamil-Regular.ttf", "/fonts/NotoSansTamil-Regular.ttf");
  doc.addFont("/fonts/NotoSansTamil-Regular.ttf", "NotoSansTamil", "normal");
  products.forEach((product: any) => {
    productMap[product.id] = product.productName;
  });

  doc.setDrawColor(169, 169, 169); 
  const marginX = 6; 
  const pageWidth = doc.internal.pageSize.getWidth(); 
  const marginYTop = 6; 
  const marginYBottom = doc.internal.pageSize.getHeight() - 6; 

  doc.line(marginX, marginYTop, marginX, marginYBottom); 
  doc.line(pageWidth - marginX, marginYTop, pageWidth - marginX, marginYBottom); 

  doc.line(marginX, marginYTop, pageWidth - marginX, marginYTop); 
  doc.line(marginX, marginYBottom, pageWidth - marginX, marginYBottom); 

  doc.setFontSize(16);
  doc.text("Masters Laundry", 10, 15);
  doc.setFontSize(10);
  doc.text("Tamil Nadu", 10, 22);
  doc.text("India", 10, 27);
  doc.text("9150006335", 10, 33);
  doc.text("info@masterslaundry.in", 10, 38);

  const emailY = 40; 
  doc.line(6, emailY + 2, pageWidth - marginX, emailY + 2);

  const currentDate = new Date();
  const formattedDate = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
  doc.setFontSize(24);
  doc.text("TAX INVOICE", 200, 36, { align: "right" });
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text("Invoice Date", 10, 50);
  doc.text(`: ${formattedDate}`, 70, 50);
  doc.text("Terms", 10, 56);
  doc.text(": Due On Receipt", 70, 56);
  doc.text("Due Date", 10, 62);
  doc.text(`: ${formattedDate}`, 70, 62);
  doc.text("P.O.#", 10, 68);
  doc.text(": SB-861", 70, 68);

  doc.setFillColor(240, 240, 240);
  doc.rect(6, 73, 198, 8, 'F');
  doc.setDrawColor(169, 169, 169);
  doc.rect(6, 73, 198, 8);
  doc.setFontSize(10);
  doc.text("Bill To", 10, 78);
  doc.text(formData.apartmentName || "", 10, 86);
  doc.text(formData.flatNo || "", 10, 92);
  doc.text(formData.mobileNo || "", 10, 98);

  const orderRows = orders.map((order: any, index: any) => [
    index + 1,
    productMap[order.productName] || 'N/A',
    `${order.quantity || 0} pcs`,
    `${(Number(order.price) || 0)}.00`,
    `${((Number(order.price) * Number(order.quantity)) || 0)}.00`,
  ]);
  autoTable(doc, {
    startY: 104,
    head: [["#", "Item & Description", "Qty", "Rate", "Amount"]],
    body: orderRows,
    styles: {
      lineColor: [169, 169, 169],
      lineWidth: 0.2,
      fontSize: 10,
      cellPadding: 2,
      cellWidth: 'auto',
      font: 'NotoSansTamil',
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      lineColor: [169, 169, 169],
      lineWidth: 0.2,
      fontSize: 10,
    },
    columnStyles: {
      0: { cellWidth: 12, halign: 'center' },   
      1: { cellWidth: 126, halign: 'left' },    
      2: { cellWidth: 20, halign: 'right' },    
      3: { cellWidth: 20, halign: 'right' },    
      4: { cellWidth: 20, halign: 'right' },    
    },
    margin: { left: 6, right: 6 },
  });

  const totalAmount = formData.totalPrice || '0';
  const finalY = (doc as any).lastAutoTable?.finalY || 0;
  doc.setFontSize(10);
  doc.text(`Sub Total:`, 180, finalY + 10, { align: "right" })
  doc.text(`${totalAmount}`, 200, finalY + 10, { align: "right" });
  doc.text(`Total:`, 180, finalY + 16, { align: "right" })
  doc.text(`Rs. ${totalAmount}`, 200, finalY + 16, { align: "right" });
  doc.setFontSize(12);
  doc.text(`Balance Due:`, 180, finalY + 22, { align: "right" })
  doc.text(`Rs. ${totalAmount}`, 200, finalY + 22, { align: "right" });

  doc.text("Notes", 10, finalY + 25);
  doc.text("Thanks for your business.", 10, finalY + 30);

  let dataUriString = doc.output("dataurlstring");
  dataUriString = dataUriString.replace(";filename=generated.pdf", "");
  return dataUriString;
};