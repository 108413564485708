import React from "react";
import ExcelJS from "exceljs";
import { Button } from "rsuite";

type TransformedService = {
  id: number;
  date: string;
  cash: number;
  phonePe: number;
  online: number;
};

const ExcelCollectionReport = ({ data, status }: { data: TransformedService[]; status: string }) => {
  const exportExcelFile = async () => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return;
    }
    console.log("Data:", data);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.columns = [
      { header: 'S.No', key: 's_no', width: 10 },
      { header: 'Date', key: 'date', width: 30 },
      { header: 'Cash', key: 'cash', width: 30 },
      { header: 'PhonePe', key: 'phonePe', width: 30 },
      { header: 'Online', key: 'online', width: 30 },
    ];
    worksheet.getRow(1).eachCell((cell: any) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9293AC' },
      };
      cell.font = { bold: true, size: 14 };
    });
    const flattenedData = data.map((item, index) => ({
      s_no: index + 1,
      date: item.date,
      cash: item.cash,
      phonePe: item.phonePe,
      online: item.online,
    }));
    flattenedData.forEach((item) => {
      const row = worksheet.addRow(item);
      row.eachCell((cell: any) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      });
    });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${status}-${formattedDate}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button style={{background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff'}} onClick={exportExcelFile}>
      Excel
    </Button>
  );
};

export default ExcelCollectionReport;
