import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { callApi } from "../utils/api";
import { showError } from "../utils/sweetAlert";
import Pagination from 'rsuite/Pagination';
import ExcelCollectionReport from './ExcelCollectionReport';
import { Button, DatePicker } from 'rsuite';
import ExcelIronCountReport from './ExcelIronCountReport';

type Service = {
  id: number;
  employeeName: string;
  ironingDate: string;
  ironingCount: string;
};

const defaultColumns = [
  { key: 'id', label: 'S.No' },
  { key: 'employeeName', label: 'Iron Person' },
  { key: 'ironingDate', label: 'Date' },
  { key: 'ironingCount', label: 'Ironing Count' }
];

const IroningCountReport = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<Service[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof Service | null, direction: 'asc' | 'desc' } | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [filterDates, setFilterDates] = useState({
    fromDate: null,
    toDate: null,
    cleared: false
  });
  const [ironPersonId, setIronPersonId] = useState('');
  const [IronPerson, setIronPerson] = useState<{ id: string, name: string }[]>([]);

  // const start = (activePage - 1) * limit;
  // const end = start + limit;
  const paginatedService = users;

  const [totalpages, setTotalPages] = useState(1);

  const totalPages = Math.ceil(totalpages / limit);

  
  const handlePageChange = (page: any) => {
    setActivePage(page);  // Update the active page
    collectionReport(page);  // Fetch data for the specific page with the current limit
  };
  
  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setActivePage(1); // Reset to the first page when the limit changes
  };
  

  const collectionReport = (page:any) => {
    setUsers([]);
    if (
      (!filterDates.fromDate || !filterDates.toDate || ironPersonId == '') &&
      filterDates.cleared !== true
    ) {
      showError(
        "Error",
        "Please select date range and iron person."
      );
      return;
    }
    const req = {
      page: page,
      limit: 30,
      searchTerm: '',
      fromDate: filterDates.fromDate,
      toDate: filterDates.toDate,
      ironPersonId: ironPersonId
    };
    callApi("POST", "ironCount.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setUsers(response["list"]);
          setTotalPages(response.totalCount)
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = (key: keyof Service) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = [...users].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setUsers(sortedData);
  };

  const getSortIcon = (key: keyof Service) => {
    if (sortConfig?.key === key) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  const handleFilterDate = (date: any, dateString: any, type: any) => {
    const formattedDate = date
      ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
      : null;
    console.log(date, dateString, formattedDate, type)
    setFilterDates((prevDates) => ({
      ...prevDates,
      [type]: formattedDate,
    }));
  };

  const handleClearDate = (type: any) => {
    setFilterDates((prevDates) => ({
      ...prevDates,
      [type]: null,
      cleared: true,
    }));
  };

  useEffect(() => {
    if (filterDates.cleared == true) {
      collectionReport(activePage);
    }
    console.log('filteredDate', filterDates)
  }, [filterDates]);

  useEffect(() => {
    console.log('filteredDate', filterDates)
  }, [paginatedService]);

  useEffect(() => {
    getIronPersonData();
  }, []);


  const getIronPersonData = () => {
    let req = {
      roleId: '2',
    };
    callApi("POST", "userRoleGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setIronPerson(response.list);
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.log("event.target", event.target.value);
    setIronPersonId(event.target.value);

  };

  return (
    <div>
      <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%' }}>Ironing Count Reports</h5>
      <div className="custom-table">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '20px 4%' }}>
          <div style={{ width: '100%' }}>
            <DatePicker
              id="FromDate"
              format="dd/MM/yyyy"
              placeholder="From Date"
              style={{ marginRight: '10px' }}
              onChange={(date, dateString) => handleFilterDate(date, dateString, 'fromDate')}
              oneTap
              className="from-date"
              value={filterDates.fromDate ? new Date(filterDates.fromDate) : null}
              onClean={() => handleClearDate('fromDate')}
            />
            <DatePicker
              id="ToDate"
              format="dd/MM/yyyy"
              placeholder="To Date"
              oneTap
              onChange={(date, dateString) => handleFilterDate(date, dateString, 'toDate')}
              className="to-date"
              placement="bottomEnd"
              onClean={() => handleClearDate('toDate')}
              value={filterDates.toDate ? new Date(filterDates.toDate) : null}
            />
            <select
              id="assignedIroningPerson"
              value={ironPersonId}
              onChange={handleInputChange}
              style={{ width: '16%', padding: '0px 1%', margin: '0px 22px 0px 10px' }}
              className='report-select'
            >
              <option value="" disabled>Select Iron Person</option>
              {IronPerson.map((person) => (
                <option key={person.id} value={person.id}>
                  {person.name}
                </option>
              ))}
            </select>
            <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }} onClick={()=> {
              collectionReport(activePage);}}>Filter</Button>
          </div>
        </div>
        {users.length === 0 ? (
          "") : (
          <div style={{ marginLeft: '4%', display: 'flex', justifyContent: 'flex-start' }}>
            <ExcelIronCountReport data={users} status='iron-count-report' />
          </div>
        )}
        <Table responsive bordered hover style={{ width: '92%', margin: '0% 4% 1%' }}>
          <thead>
            <tr>
              {defaultColumns.map(column => (
                <th
                  key={column.key}
                  onClick={() => handleSort(column.key as keyof Service)}
                  style={{ cursor: 'pointer' }}
                >
                  {column.label} {getSortIcon(column.key as keyof Service)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedService.length === 0 ? (
              <tr>
                <td colSpan={defaultColumns.length} style={{ textAlign: 'center' }}>
                  No Iron Count Report yet
                </td>
              </tr>
            ) : (
              paginatedService.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.employeeName}</td>
                  <td>{user.ironingDate}</td>
                  <td>{user.ironingCount}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {/* <Pagination
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          size="md"
          total={users.length}
          limit={limit}
          limitOptions={[30, 50]}
          activePage={activePage}
          onChangePage={handlePageChange}
          onChangeLimit={handleLimitChange}
          style={{ marginLeft: '4%', marginRight: '4%' }}
        /> */}
        <Pagination
 key={activePage} // Ensure pagination component re-renders when activePage changes
 layout={['total', '-', '|', 'pager', 'skip']} // Show skip option
        size="md"
        total={totalpages}
        limit={limit}
        limitOptions={[30, 50]}
        activePage={activePage}
        pages={totalPages > 0 ? totalPages : totalPages}
        maxButtons={5}
        prev
        last
        next
        first
        onChangePage={handlePageChange}
        onChangeLimit={handleLimitChange}
        style={{ marginLeft: '4%', marginRight: '4%' }}
      />
      </div>
    </div>
  );
};

export default IroningCountReport;
