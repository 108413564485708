import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { callApi } from "../utils/api";
import { showError } from "../utils/sweetAlert";
import Pagination from 'rsuite/Pagination';
import ExcelCollectionReport from './ExcelCollectionReport';
import { Button, DatePicker } from 'rsuite';

type Service = {
  id: number;
  paymentType: string;
  collection: string;
  transaction_date: string;
};

type TransformedService = {
  id: number;
  date: string;
  cash: number;
  phonePe: number;
  online: number;
};

const defaultColumns = [
  { key: 'id', label: 'S.No' },
  { key: 'date', label: 'Date' },
  { key: 'cash', label: 'Cash' },
  { key: 'phonePe', label: 'PhonePe' },
  { key: 'online', label: 'Online' },
];

const CollectionReport = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<TransformedService[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof Service | null, direction: 'asc' | 'desc' } | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [filterDates, setFilterDates] = useState({
    fromDate: null,
    toDate: null,
    cleared: false
  });

  // const start = (activePage - 1) * limit;
  // const end = start + limit;
  // const paginatedService = users.slice(start, end);
  const [totalpages, setTotalPages] = useState(1);

  const totalPages = Math.ceil(totalpages / limit);

  const paginatedService = users;
  
  console.log("paginatedService------------>",paginatedService);
  
const handlePageChange = (page: any) => {
  setActivePage(page);  // Update the active page
  collectionReports(page);  // Fetch data for the specific page with the current limit
};

const handleLimitChange = (newLimit: any) => {
  setLimit(newLimit);
  setActivePage(1); // Reset to the first page when the limit changes
};

  useEffect(() => {
    collectionReports(activePage);
  }, []);

  const transformData = (data: Service[]) => {
    const groupedData = data.reduce((acc: Record<string, any>, curr: Service) => {
      const { transaction_date, paymentType, collection } = curr;
      if (!acc[transaction_date]) {
        acc[transaction_date] = {
          date: transaction_date,
          cash: 0,
          phonePe: 0,
          online: 0
        };
      }
      if (paymentType.toLowerCase().includes("cash")) {
        acc[transaction_date].cash += parseFloat(collection);
      } else if (paymentType.toLowerCase().includes("phonepe")) {
        acc[transaction_date].phonePe += parseFloat(collection);
      } else if (paymentType.toLowerCase().includes("online")) {
        acc[transaction_date].online += parseFloat(collection);
      }
      return acc;
    }, {});
    return Object.values(groupedData);
  };


  const collectionReports = (page:any) => {
    const req = {
      page: page,
      limit: 30,
      searchTerm: '',
      fromDate: filterDates.fromDate,
      toDate: filterDates.toDate
    };
    callApi("POST", "collectionReport.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          const transformed = transformData(response["list"]);
          setUsers(transformed);
          console.log("transformed------------>",transformed);
          
          setTotalPages(response.totalCount)
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = (key: any) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = [...users].sort((a: any, b: any) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setUsers(sortedData);
  };

  const getSortIcon = (key: keyof Service) => {
    if (sortConfig?.key === key) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  const handleFilterDate = (date: any, dateString: any, type: any) => {
    const formattedDate = date
      ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
      : null;
    console.log(date, dateString, formattedDate, type)
    setFilterDates((prevDates) => ({
      ...prevDates,
      [type]: formattedDate,
    }));
  };

  const handleClearDate = (type: any) => {
    setFilterDates((prevDates) => ({
      ...prevDates,
      [type]: null,
      cleared: true,
    }));
  };

  useEffect(() => {
    if (filterDates.cleared == true) {
      collectionReports(activePage);
    }
    console.log('filteredDate', filterDates)
  }, [filterDates])

  return (
    <div>
      <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%' }}>Collection Reports</h5>
      <div className="custom-table">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '20px 4%' }}>
          <div>
            <DatePicker
              id="FromDate"
              format="dd/MM/yyyy"
              placeholder="From Date"
              style={{ marginRight: '10px' }}
              onChange={(date, dateString) => handleFilterDate(date, dateString, 'fromDate')}
              oneTap
              className="from-date"
              value={filterDates.fromDate ? new Date(filterDates.fromDate) : null}
              onClean={() => handleClearDate('fromDate')}
            />
            <DatePicker
              id="ToDate"
              format="dd/MM/yyyy"
              placeholder="To Date"
              oneTap
              onChange={(date, dateString) => handleFilterDate(date, dateString, 'toDate')}
              className="to-date"
              placement="bottomEnd"
              onClean={() => handleClearDate('toDate')}
              value={filterDates.toDate ? new Date(filterDates.toDate) : null}
            />
            <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }} onClick={()=> {
              collectionReports(activePage);
            }}>Filter</Button>
          </div>
          {users.length === 0 ? (
            "") : (
            <div style={{ marginRight: '1%', display: 'flex', justifyContent: 'flex-end' }}>
              <ExcelCollectionReport data={users} status='collection-report' />
            </div>
          )}
        </div>
        <Table responsive bordered hover style={{ width: '92%', margin: '0% 4% 1%' }}>
          <thead>
            <tr>
              {defaultColumns.map(column => (
                <th
                  key={column.key}
                  onClick={() => handleSort(column.key as keyof Service)}
                  style={{ cursor: 'pointer' }}
                >
                  {column.label} {getSortIcon(column.key as keyof Service)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedService.length === 0 ? (
              <tr>
                <td colSpan={defaultColumns.length} style={{ textAlign: 'center' }}>
                  No Collection Report yet
                </td>
              </tr>
            ) : (
              paginatedService.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.date}</td>
                  <td>{user.cash}</td>
                  <td>{user.phonePe}</td>
                  <td>{user.online}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {/* <Pagination
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          size="md"
          total={users.length}
          limit={limit}
          limitOptions={[30, 50]}
          activePage={activePage}
          onChangePage={handlePageChange}
          onChangeLimit={handleLimitChange}
          style={{ marginLeft: '4%', marginRight: '4%' }}
        /> */}
        <Pagination
 key={activePage} // Ensure pagination component re-renders when activePage changes
 layout={['-', '|', 'pager', 'skip']} // Show skip option
        size="md"
        total={totalpages}
        limit={limit}
        limitOptions={[30, 50]}
        activePage={activePage}
        pages={totalPages > 0 ? totalPages : totalPages}
        maxButtons={5}
        prev
        last
        next
        first
        onChangePage={handlePageChange}
        onChangeLimit={handleLimitChange}
        style={{ marginLeft: '4%', marginRight: '4%' }}
      />

      </div>
    </div>
  );
};

export default CollectionReport;
