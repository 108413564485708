import React, { useEffect, useState } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import { Modal, TagPicker } from 'rsuite';
import RemindIcon from '@rsuite/icons/legacy/Remind';
import EditIcon from '../../assets/edit.png';
import deleteIcon from "../../assets/delete.png";
import './UserManagement.css';
import ExcelProductManagement from './ExcelProductManagement';
import Pagination from 'rsuite/Pagination';

type Product = {
  id: number;
  productName: string;
  price: string;
};

const defaultColumns = [
  { key: 'id', label: 'S.No' },
  { key: 'productName', label: 'Product Name' },
  { key: 'price', label: 'Product Price' },
  { key: 'actions', label: 'Actions' }
];

const ProductManagement = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<Product[]>([]);
  const [selectedUser, setSelectedUser] = useState<Product | null>(null);
  const [columnKeys, setColumnKeys] = useState(defaultColumns?.map(col => col.key));
  const [sortConfig, setSortConfig] = useState<{ key: keyof Product | null, direction: 'asc' | 'desc' } | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(30);

  // const start = (activePage - 1) * limit;
  // const end = start + limit;
  const paginatedProduct = users;


  const [totalpages, setTotalPages] = useState(1);

  const totalPages = Math.ceil(totalpages / limit);


    
  const handlePageChange = (page: any) => {
    setActivePage(page);  // Update the active page
    getProduct(page);
  // Fetch data for the specific page with the current limit
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setActivePage(1); // Reset to the first page when the limit changes
  };

  const handleOpen = (user: any) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedUser(null);
    setOpen(false);
  };

  useEffect(() => {
    getProduct(activePage);
  }, [searchTerm]);

  const getProduct = (page:any) => {
    let req = {
      page: page,
      limit: 30,
      searchTerm: searchTerm,
    };
    callApi("POST", "productList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setUsers(response["list"]);
          setTotalPages(response.totalCount)
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleEdit = (user: any) => {
    navigate('/layout/product-update', { state: { user } });
  };

  const handleDelete = () => {
    if (selectedUser) {
      console.log(`Delete user with ID: ${selectedUser.id}`);
      let req = {
        id: selectedUser.id
      };
      callApi("POST", "productDelete.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          if (response["success"] === true) {
            showSuccess("Success", response.message);
            getProduct(activePage);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    handleClose();
  };

  const handleAddNew = () => {
    let user = '';
    navigate('/layout/product-update', { state: { user } });
  };

  const filteredColumns = defaultColumns.filter(column => columnKeys.includes(column.key));

  const handleSort = (key: keyof Product) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = [...users].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setUsers(sortedData);
  };

  const getSortIcon = (key: keyof Product) => {
    if (sortConfig?.key === key) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  return (
    <div>
      <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>Product Management</h5>
      <div className="custom-table">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '98%' }} className='mobile-buttons'>
          <TagPicker
            data={defaultColumns}
            labelKey="label"
            valueKey="key"
            value={columnKeys}
            onChange={setColumnKeys}
            cleanable={false}
            style={{ width: '50%', marginLeft: '4%' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4%', marginBottom: '2%', width: '18%' }} >
            {users.length === 0 ? (
              "") : (
              <div style={{ marginRight: '1%', display: 'flex', justifyContent: 'flex-end' }}>
                <ExcelProductManagement data={users} status='product-management' />
              </div>
            )}
            <Button onClick={handleAddNew} style={{ marginRight: '4%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', height: '36px' }}>
              Add Product
            </Button>
          </div>
        </div>
        <Form.Control
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '30%', marginLeft: '4%', marginBottom: '20px', marginTop: '20px' }}
        />
        <Table responsive bordered hover style={{ width: '92%', margin: '0% 4% 1%' }}>
          <thead>
            <tr>
              {filteredColumns?.map(column => (
                <th key={column.key} onClick={() => handleSort(column.key as keyof Product)} style={{ cursor: 'pointer' }}>
                  {column.label} {getSortIcon(column.key as keyof Product)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedProduct.length === 0 ? (
              <tr>
                <td colSpan={filteredColumns.length} style={{ textAlign: 'center' }}>
                  No Products added yet
                </td>
              </tr>
            ) : (
              paginatedProduct?.map((user, index) => (
                <tr key={user.id}>
                  {columnKeys.includes('id') && <td>{index + 1}</td>}
                  {columnKeys.includes('productName') && <td>{user.productName}</td>}
                  {columnKeys.includes('price') && <td>{user.price}</td>}
                  {columnKeys.includes('actions') && (
                    <td>
                      <img onClick={() => handleEdit(user.id)} style={{ width: '16px', marginRight: '10px', cursor: 'pointer' }} src={EditIcon} alt="edit-icon" />
                      <img onClick={() => handleOpen(user)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <Pagination
 key={activePage} // Ensure pagination component re-renders when activePage changes
 layout={['total', '-', '|', 'pager', 'skip']} // Show skip option
        size="md"
        total={totalpages}
        limit={limit}
        limitOptions={[30, 50]}
        activePage={activePage}
        pages={totalPages > 0 ? totalPages : totalPages}
        maxButtons={5}
        prev
        last
        next
        first
        onChangePage={handlePageChange}
        onChangeLimit={handleLimitChange}
        style={{ marginLeft: '4%', marginRight: '4%' }}
      />
        <Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} size="xs">
          <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <RemindIcon style={{ color: '#ffb300', fontSize: 24, marginBottom: '4%' }} />
            Are you sure you want to delete the product "{selectedUser?.productName}"?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleDelete} style={{ marginRight: '2%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)' }}>
              Delete
            </Button>
            <Button onClick={handleClose} style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)' }}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ProductManagement;
